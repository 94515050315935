<template>
  <div class="coupon-exchange-setting">
    <PageTitle title="瑞乘優惠券設定" hideBtn @btnClick="onCreate" />
    <PospalCouponTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import PospalCouponTable from './components/PospalCouponTable.vue'
import { GetPospalCoupon, GetPospalCouponCount } from '@/api/pospal'

export default defineComponent({
  name: 'PospalCouponList',
  components: {
    PageTitle,
    PospalCouponTable,
  },
  setup (props) {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const onCreate = () => {
      router.push({ name: 'ItemCreatePage' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetPospalCoupon, payload),
        fetchDataCount(GetPospalCouponCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
    }
  },
})
</script>
