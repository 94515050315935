<template>
  <el-drawer
    :visible="show"
    title="優惠券詳情"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    @close="onClose"
  >
    <div v-loading="!loaded || loading.refresh" class="content-wrapper">
      <el-form label-position="left" label-width="150px">
        <el-form-item v-for="field in displayData.baseFields" :key="field.key" :label="field.label">
          {{ field.value }}
        </el-form-item>
      </el-form>

      <div>
        <p class="text-primary-100 font-medium text-[18px] mb-[24px]">核銷碼發送狀態</p>
        <el-form label-position="left" label-width="150px">
          <el-form-item v-for="field in displayData.codeFields" :key="field.key" :label="field.label">
            {{ field.value }}
          </el-form-item>
        </el-form>
        <div class="flex justify-end mt-[12px]">
          <el-button type="primary" :loading="loading.resend" @click="resendCouponCode">補發核銷碼</el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, computed, ref, onBeforeMount, reactive } from 'vue'
import { get, isNumber } from 'lodash'
import { FindPospalCoupon, ResendPospalCouponCode } from '@/api/pospal'
import { formatDate } from '@/utils/date'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'CustomFlowChangeLog',
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const couponData = ref({})
    const show = ref(true)
    const loaded = ref(false)
    const loading = reactive({
      refresh: false,
      resend: false,
    })
    const { shopId } = useShop()
    const onClose = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }
    const displayData = computed(() => {
      const startDate = get(couponData.value, 'extraSetting.posPalConfig.startDate')
      const endDate = get(couponData.value, 'extraSetting.posPalConfig.endDate')
      const beginDays = get(couponData.value, 'extraSetting.posPalConfig.beginDays')
      const avaliableDays = get(couponData.value, 'extraSetting.posPalConfig.avaliableDays')
      return {
        baseFields: [
          { label: '優惠券名稱', key: 'name', value: get(couponData.value, 'name') },
          { label: '優惠券規則 Uid', key: 'pospalEcouponId', value: get(couponData.value, 'extraStatus.pospalEcouponId') },
          { label: '有效週期', key: 'dateRange', value: startDate || endDate ? `${formatDate(startDate)} ~ ${formatDate(endDate)}` : '-' },
          { label: '發放後幾天生效', key: 'beginDays', value: isNumber(beginDays) ? beginDays : '-' },
          { label: '發放後有效天數', key: 'avaliableDays', value: isNumber(avaliableDays) ? avaliableDays : '-' },
        ],
        codeFields: [
          { label: '發放成功張數', key: 'syncedCount', value: get(couponData.value, 'syncedCount') || '0' },
          { label: '發放失敗張數', key: 'unsyncedCount', value: get(couponData.value, 'unsyncedCount') || '0' },
        ],
      }
    })

    const resendCouponCode = async () => {
      console.log('resendCouponCode')

      if (loading.resend) return
      loading.resend = true

      const [res, err] = await ResendPospalCouponCode({
        shopId: shopId.value,
        id: get(props.data, 'id'),
      })
      loading.resend = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('核銷碼已補發')
      refresh()
    }

    const refresh = async () => {
      if (loading.refresh) return
      loading.refresh = true
      const [res, err] = await FindPospalCoupon({
        shopId: shopId.value,
        id: get(props.data, 'id'),
      })
      loaded.value = true
      loading.refresh = false
      if (err) return
      couponData.value = res
    }

    onBeforeMount(async () => {
      refresh()
    })

    return { onClose, show, displayData, loading, resendCouponCode, loaded }
  },
})
</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply px-[24px] pb-[100px];
  @apply flex flex-col gap-[60px];
}
::v-deep .el-form-item{
  @apply mb-0;
}
</style>
